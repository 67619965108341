<template>
  <div v-if="isDataLoadedFromServer">
    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      persistent
      max-width="1000px"
    >
      <div class="bg-white poppins">
        <div class="modal-header align-center">
          <h3 class="mb-0 font-weight-bolder">Edit Record</h3>
          <div
            class="btn btn-sm btn-icon btn-active-light-info"
            @click="toggleModal"
          >
            <span class="svg-icon svg-icon-5">
              <v-icon size="22">mdi-close</v-icon>
            </span>
          </div>
        </div>
        <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
          <!--begin::Body-->
          <form id="editForm">
            <div class="h-75px">
              <v-select
                v-model="formData.type_id"
                label="Type"
                item-text="text"
                item-value="index"
                :items="serverData.types"
                clearable
                outlined
                required
                dense
                hide-details
                :error-messages="type_idErrors"
                @input="$v.formData.type_id.$touch()"
                @blur="$v.formData.type_id.$touch()"
              ></v-select>
            </div>

            <div class="h-75px" v-if="isOrganization">
              <v-select
                v-model="formData.organization_id"
                label="Organization"
                item-text="text"
                item-value="index"
                :items="serverData.organization"
                clearable
                outlined
                dense
                hide-details
              ></v-select>
            </div>

            <div class="h-75px" v-else>
              <v-autocomplete
                v-model="formData.client_id"
                label="Client"
                item-text="text"
                item-value="index"
                :items="serverData.clients"
                clearable
                outlined
                dense
                hide-details
              ></v-autocomplete>
            </div>

            <div class="h-75px">
              <v-autocomplete
                v-model="formData.country_id"
                label="Country"
                item-text="text"
                item-value="index"
                :items="serverData.countries"
                clearable
                outlined
                dense
                hide-details
                @change="onCountryChange"
              ></v-autocomplete>
            </div>

            <div class="h-75px">
              <v-autocomplete
                v-model="formData.state_id"
                label="State"
                item-text="title"
                item-value="id"
                :items="states"
                clearable
                outlined
                dense
                :error-messages="
                  isOrganization && !formData.organization_id
                    ? ['Select Organization']
                    : []
                "
                :readonly="isOrganization && !formData.organization_id"
                @change="onStateChange"
              ></v-autocomplete>
            </div>

            <div class="h-75px">
              <v-autocomplete
                v-model="formData.city_id"
                label="City"
                item-text="title"
                item-value="id"
                :items="cities"
                clearable
                outlined
                dense
                :error-messages="
                  isOrganization && !formData.organization_id
                    ? ['Select Organization']
                    : []
                "
                :readonly="isOrganization && !formData.organization_id"
                @change="onCityChange"
              ></v-autocomplete>
            </div>

            <div class="h-75px">
              <v-autocomplete
                v-model="formData.area_id"
                label="Area"
                item-text="title"
                item-value="id"
                :items="areas"
                clearable
                dense
                outlined
                :error-messages="
                  isOrganization && !formData.organization_id
                    ? ['Select Organization']
                    : []
                "
                :readonly="isOrganization && !formData.organization_id"
              ></v-autocomplete>
            </div>

            <div class="h-75px">
              <v-text-field
                v-model="formData.address"
                label="Address"
                clearable
                outlined
                dense
              ></v-text-field>
            </div>

            <div class="h-75px">
              <v-text-field
                v-model="formData.address_2"
                label="Address 2"
                clearable
                outlined
                dense
              ></v-text-field>
            </div>

            <div class="h-75px">
              <v-text-field
                v-model="formData.postal_code"
                label="Postal code"
                clearable
                outlined
                dense
              ></v-text-field>
            </div>
          </form>
          <!--end::Body-->
          <div class="my-4 d-flex flex-grow-1 align-end justify-center">
            <button
              type="reset"
              class="btn btn-light mr-3 px-5 py-3 ls1"
              @click="resetEditForm"
            >
              Clear
            </button>
            <button
              type="submit"
              class="btn btn-info px-5 py-3 ls1"
              @click="submitEditForm"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  name: "EditItem",
  mixins: [validationMixin],
  validations: {
    formData: {
      type_id: { required },
    },
  },
  props: ["refresher", "pageLoader"],
  data: () => ({
    dialog: false,
    serverData: null,
    formData: {
      id: null,
      type_id: 1,
      organization_id: null,
      client_id: null,
      country_id: null,
      state_id: null,
      city_id: null,
      area_id: null,
      address: null,
      address_2: null,
      postal_code: null,
    },
  }),
  methods: {
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;

      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    loadDataFromServer() {
      this.pageLoader(true);
      let data = { id: this.$store.getters.getItemForAction.id };
      ApiService.post("/organization/addresses/edit", data)
        .then((response) => {
          this.serverData = response.data;
          this.formData = response.data.address;
          this.onCountryChange(response.data.address.country_id);
          this.onStateChange(response.data.address.state_id);
          this.onCityChange(response.data.address.city_id);
          this.pageLoader(false);
        })
        .catch(() => {
          this.dialog = false;
          this.pageLoader(false);
        });
    },

    submitEditForm() {
      this.$v.$touch();
      this.pageLoader(true);
      ApiService.post("/organization/addresses/update", this.formData)
        .then(() => {
          Swal.fire({
            title: "Updated",
            text: `Address data has been updated`,
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          this.toggleModal();
          this.refresher();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    onCountryChange(val) {
      if (val) {
        this.pageLoader(true);
        let data = { country: val };
        if (this.isOrganization && this.formData.organization_id) {
          data["organization_id"] = this.formData.organization_id;
        }
        ApiService.post("/address/states/search", data)
          .then((response) => {
            this.serverData.states = response.data.states;
            this.serverData = { ...this.serverData };
            this.pageLoader(false);
            this.onStateChange(null);
            this.onCityChange(null);
          })
          .catch(() => {
            this.pageLoader(false);

            this.dialog = false;
          });
      }
    },
    onStateChange(val) {
      if (val) {
        this.pageLoader(true);
        let data = { state: val };
        if (this.isOrganization && this.formData.organization_id) {
          data["organization_id"] = this.formData.organization_id;
        }
        ApiService.post("/address/cities/search", data)
          .then((response) => {
            this.serverData.cities = response.data.cities;
            this.serverData = { ...this.serverData };
            this.pageLoader(false);
            this.onCityChange(null);
          })
          .catch(() => {
            this.pageLoader(false);
            this.dialog = false;
          });
      } else this.serverData.cities = [];
    },
    onCityChange(val) {
      if (val) {
        this.pageLoader(true);
        let data = { city: val };
        if (this.isOrganization && this.formData.organization_id) {
          data["organization_id"] = this.formData.organization_id;
        }
        ApiService.post("/address/areas/search", data)
          .then((response) => {
            this.serverData.areas = response.data.areas;
            this.serverData = { ...this.serverData };
            this.pageLoader(false);
          })
          .catch(() => {
            this.pageLoader(false);
            this.dialog = false;
          });
      } else this.serverData.areas = [];
    },
    resetEditForm() {
      this.$v.$reset();
      this.formData = {
        type_id: null,
        organization_id: null,
        client_id: null,
        country_id: null,
        state_id: null,
        city_id: null,
        area_id: null,
        address: null,
        address_2: null,
        postal_code: null,
      };
    },
    toggleModal() {
      if (this.dialog) {
        this.resetEditForm();
      } else {
        this.loadDataFromServer();
      }
      this.dialog = !this.dialog;
    },
  },
  computed: {
    isOrganization() {
      return this.formData.type_id === 1;
    },
    isDataLoadedFromServer: function () {
      return !!this.serverData;
    },
    type_idErrors() {
      return this.handleFormValidation("type_id", this);
    },
    states: function () {
      return this.serverData.states;
    },
    cities: function () {
      return this.serverData.cities;
    },
    areas: function () {
      return this.serverData.areas;
    },
  },
};
</script>
